import React, { useEffect,useState } from 'react';
import {Image as RnImage, StyleSheet, Text, View,ScrollView,Dimensions,Pressable ,Platform} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import moment from "moment";
import {Image} from 'expo-image'
import axios from 'axios';
import { Buffer } from 'buffer'
import * as Device from 'expo-device';
import { Asset,useAssets } from 'expo-asset';
//import { isNode, isBrowser, isWebWorker, isJsDom, isDeno, isWebKit, isSafari } from './WebJs'
import { NativeBaseProvider, Box } from "native-base";
import { Center,VStack,Progress } from 'native-base';
import { osName,getUA,browserName,BrowserView, MobileView, isBrowser, isMobile,isChrome,isAndroid,isIOS,isSafari,isMacOs,isMobileSafari,isMobileOnly } from 'react-device-detect';
import fileDownload from 'js-file-download';
import browser from "browser-tool"

import App1 from './App1';
import App2 from './App2';
import AES from './Aes';

// if(!__DEV__){
//   (() => {
//     function block() {
//       if (window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200) {
//         document.body.innerHTML = "检测到非法调试,请关闭后刷新重试!";
//       }
//       setInterval(() => {
//         (function () {
//           return false;
//         }
//         ['constructor']('debugger')
//         ['call']());
//       }, 50);
//     }
//     try {
//       block();
//     } catch (err) { }
//   })();
// }


export default function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const CurTime = moment().format('YYYYMMDDHHmm');
  const paramA = searchParams.get('a');
  const paramB = searchParams.get('b');
  const paramU = searchParams.get('u');
  const [DownloadUri, setDownloadUri] = useState(null)
  const [DownloadProgress, setDownloadProgress] = useState(0)
  const [isDownload, setIsDownload] = useState(false)
  const [isCompleted, setisCompleted] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isShowNoSafari, setisShowNoSafari] = useState(false)
  const [isShowIosTip, setIsShowIosTip] = useState(false)

  const [IsWxQQ, setIsWxQQ] = useState(false)
  const [assets,error] = useAssets([require('./assets/iosTutorial.png'),require('./assets/wxqq.png')]);

  //const h5 = 'https://h5.dadsxqq.com'
  const h5 = 'https://vhgiy.icu'
  const url_api = 'https://cdn.dlklada.com/User/Download'
  //const url_api = 'http://10.10.10.101:5000/User/Download'


    const fetchData = async () => {
      try {
        var key;
        // 根据参数paramA和paramU的值设置key
        if(paramA){
          key = "a";
        }else if(paramU){
          key = "u";
        }  
        //alert(paramA +" / "+ paramU)
        // 使用axios发送POST请求，并将key和paramA或paramU作为参数传递给后端
        const response = await axios.post(url_api,{BindType:key,extensionCode:paramA || paramU})
        // 从响应数据中解构出Success、Data、Msg和ErrorCode字段
        const { Success, Data, Msg, ErrorCode } = response.data;
        // 使用AES解密Data字段，并将解密后的数据解析为JSON格式
        const data = JSON.parse(AES.Decrypt(Data))
        //console.log(data)
        // 将解密后的数据设置给setDownloadUri函数
        setDownloadUri(data)
      } catch (error) {
        // 捕获异常，弹出警告提示用户下载失败，并建议检查网络连接和刷新页面
        alert("网络连接异常，正在重新刷新页面！")
        // 重新加载页面，重新发送请求
        location.reload()
      }
  
    }

  const downloadFile = async (url,filename = "1.apk") => {
    try {
      // 设置下载状态为进行中
      setIsDownload(true)
      const res = await axios.get(url, {
        // 下载进度回调函数
        onDownloadProgress: progressEvent => {
          // 计算下载进度百分比
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // 更新下载进度
          //console.log("进度:",percentCompleted,progressEvent.loaded,progressEvent.total)
          setDownloadProgress(percentCompleted)
        },
        // 设置响应类型为blob
        responseType: 'blob',
      })
      // 设置下载完成状态为已完成
      setisCompleted(true)
      // 设置下载状态为已完成
      setIsDownload(false)
      // 调用文件下载函数，传入响应数据和文件名
      fileDownload(res.data, filename);
    } catch (error) {
      alert("下载失败，请检查网络连接或刷新页面！")
      //alert(JSON.stringify(error))
      // 打印错误信息
      //console.log(error)
      // 设置下载状态为已完成
      setIsDownload(false)
      location.reload()
    }
  }
  const openH5 = async () => {
    location.href  = h5;
  };
  
  function getRandomItem(list) {
    // 如果列表为空
    if (list.length === 0) {
      // 返回null，或者你可以抛出一个错误，表示列表为空
      return null; // 或者你可以抛出一个错误，列表为空
    }
    // 生成一个随机索引
    const randomIndex = Math.floor(Math.random() * list.length);
    // 返回列表中对应随机索引的元素
    return list[randomIndex];
  }

  const OpenDownload=(click=true)=>{
    //alert(1)
    if(DownloadUri){
      // 如果正在下载，则直接返回
      if(isDownload){
        return 
      }
      if(isIOS){
        // 如果不是Safari浏览器
        if(isSafari == false){
          // 显示非Safari浏览器提示
          setisShowNoSafari(true);
          // 2秒后隐藏非Safari浏览器提示
          setTimeout(() => setisShowNoSafari(false), 2000);
          return 
        }

        // 显示iOS设备提示
        setIsShowIosTip(true)
        // 跳转到iOS下载链接
        var url = getRandomItem(DownloadUri.DownDomainList)
        location.href = url + ".mobileconfig";
        //location.href = DownloadUri.ios_url;

        // 获取iOS设备版本号并转为整数
        const osVersion = parseInt(Device.osVersion, 10);
        // 如果iOS设备版本号小于17
        if(osVersion < 17){
          // 2.5秒后跳转到另一个iOS下载链接
          setTimeout(() => {
            //location.href = DownloadUri.ios_url2
            location.href = url + ".mobileprovision";
          }, 3000);
        }
      }else{
        var url = getRandomItem(DownloadUri.DownDomainList)
        DownloadUri.Browser.forEach(element => {
          if(element == browserName){
            // 如果paramB为空
            if(paramB == null){
              // 显示下载提示
              setIsShow(true)
            }
            if(click == true){
              // 显示下载提示
              setIsShow(true)
            }
            //alert('111')
            // 下载文件，并指定文件名
            //downloadFile(DownloadUri.android_url2,DownloadUri.appname+CurTime+".apk")
            downloadFile(url + '.aaa',DownloadUri.appname+CurTime+".apk")
            return
          }
         
        });

        
        if(url){
          //下载文件，并指定文件名
          location.href  = url + ".apk"; //DownloadUri.android_url;
        }else{
          alert("下载地址错误，请检查网络连接或刷新页面！");
          location.reload();
        }
       
      }
    } 
  }

  const devices = (function () {
    var u = navigator.userAgent;
    return {
        isAndroid: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        isIOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        isSafari: u.indexOf('Safari') > -1,
        isQQ: u.match(/QQ\/[0-9]/i),
        isTb: u.match(/tieba\/[0-9]/i),
        isWx: u.match(/MicroMessenger\/[0-9]/i),
    }
  })();

  useEffect(() => {
    OpenDownload(false)  
  },[DownloadUri])

  useEffect(() => {
    //alert(osName+"/"+browserName+"/" + isBrowser+ "/" + isMobileOnly +" UA:"+getUA)
    fetchData()
    if (devices.isQQ || devices.isWx || devices.isTb) {
      setIsWxQQ(true)
    }
  },[])

  useEffect(() => {
    if(isCompleted){
      setTimeout(() => {
        setDownloadProgress(0)
        setisCompleted(false)
        setIsShow(false)
      }, 3000);
     
    }
     
  },[isCompleted])


  return (
    <View style={{flex:1,backgroundColor:'#fff'}}>
      <NativeBaseProvider>
        {paramB == "1" &&  <App1 func={OpenDownload} openH5={openH5}/>}
        {paramB == "2" &&  <App2 func={OpenDownload} openH5={openH5}/>}
        {
            isShow ?
            <View style={{position:'fixed',justifyContent:'center',alignItems:'center',bottom:0,left:0,top:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.1)'}}>
                  <View style={{height:100,width:'90%',backgroundColor:'#ffff',justifyContent:'center',alignItems:'center',borderRadius:12}}>
                  { 
                    isCompleted ?
                    <Text>下载完成 (刷新页面可重新下载)</Text> 
                    :
                    <Text>正在下载APP，请耐心等待安装.....</Text>       
                  }
                  <Box w="90%" style={{marginTop:10}} >
                      <Progress size="2xl" colorScheme="red" value={DownloadProgress} />
                      <Text style={{position:'absolute',left:'46%',marginTop:2,fontWeight:'bold',color:'#fff'}}>{DownloadProgress}%</Text>
                  </Box>
                  </View>
                </View>
          :null     
        }

        {
          isShowNoSafari ?
            <Pressable onPress={()=>setisShowNoSafari(!isShowNoSafari)} style={{position:'fixed',justifyContent:'center',alignItems:'center',bottom:0,left:0,top:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
                  <View style={{width:'70%',height:80,backgroundColor:'rgba(0, 0, 0, 0.7)',justifyContent:'center',alignItems:'center',borderRadius:12}}>

                      <Text style={{marginTop:2,fontWeight:'bold',color:'#fff'}}>请在Safari浏览器打开网页哦，</Text>
                      <Text style={{marginTop:2,fontWeight:'bold',color:'#fff'}}>其他浏览器下载打不开！</Text>
  
                  </View>
                </Pressable>
          :null     
        }

        {
          isShowIosTip ?
          <Pressable onPress={()=>setIsShowIosTip(!isShowIosTip)}  style={{position:'fixed',zIndex:999, justifyContent:'center',alignItems:'center',bottom:0,left:0,top:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.4)'}}>
                <View style={{height:'80%',width:'90%',backgroundColor:'#ffff',justifyContent:'center',alignItems:'center',borderRadius:12}}>
                <Image
            recyclingKey={"isShowIosTip"}
              style={{
                //flex:1,
                width: '100%',
                height: '100%',
                //backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={assets[0]}
              contentFit='fill'
          />
                </View>
              </Pressable>
        :null     
        }
      {/* {
        IsWxQQ && assets ?
        <View style={{position:"absolute", top: 0, left: 0, width: "100%", height: "100%",overflow:'hidden', display:'flex', backgroundColor:'rgba(0, 0, 0, 0.8)'}}>
              <Image
                recyclingKey={"IsWxQQ"}
                style={{
                  position:'absolute',
                  width:'100%',
                  height:'50%',
                }}
                source={assets[1]}
                contentFit='fill'
              />
        
          </View>
          :null
      } */}
      </NativeBaseProvider>
    </View>
  );
}
